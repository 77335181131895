@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");

:root {
  --color-white-100: hsl(206, 5%, 100%);
  --color-white-200: hsl(206, 5%, 90%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 65%);
  --color-white-500: hsl(206, 5%, 50%);

  --color-black-100: hsl(213, 20%, 10%);
  --color-black-200: hsl(213, 23%, 8%);
  --color-black-300: hsl(214, 21%, 6%);
  --color-black-400: hsl(210, 21%, 6%);
  --color-black-500: hsl(216, 22%, 4%);
  --color-black-600: hsl(220, 18%, 3%);
  --color-black-700: hsl(220, 27%, 2%);
  --color-black-800: hsl(180, 20%, 1%);

  --color-blue-100: hsl(214, 95%, 93%);
  --color-blue-200: hsl(213, 97%, 87%);
  --color-blue-300: hsl(212, 96%, 78%);
  --color-blue-400: hsl(213, 94%, 68%);
  --color-blue-500: hsl(217, 91%, 60%);
  --color-blue-600: hsl(221, 83%, 53%);
  --color-blue-700: hsl(224, 76%, 48%);
  --color-blue-800: hsl(226, 71%, 40%);
  --color-blue-900: hsl(224, 64%, 33%);

  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// html {
//   font-size: 100%;
//   box-sizing: inherit;
//   scroll-behavior: smooth;
//   height: -webkit-fill-available;
// }

// body {
//   font-family: "Rubik", sans-serif;
//   font-size: clamp(1rem, 2vw, 1.125rem);
//   font-weight: 400;
//   line-height: 1.5;
//   height: -webkit-fill-available;

//   //   color: var(--color-white-100);
// }

main {
  overflow: hidden;
}

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

// Element Section
.section {
  margin: 0 auto;
  padding: 6rem 0 1rem;
}

.container {
  max-width: 75rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.centered {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
}

.heading {
  &-xl {
    font-family: inherit;
    font-size: clamp(2.648rem, 6vw, 4.241rem);
    font-weight: 700;
    line-height: 1.15;
    letter-spacing: -1px;
  }

  &-lg {
    font-family: inherit;
    font-size: clamp(2.179rem, 5vw, 3.176rem);
    font-weight: 700;
    line-height: 1.15;
    letter-spacing: -1px;
  }

  &-md {
    font-family: inherit;
    font-size: clamp(1.794rem, 4vw, 2.379rem);
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: -1px;
  }

  &-sm {
    font-family: inherit;
    font-size: clamp(1.476rem, 3vw, 1.782rem);
    font-weight: 600;
    line-height: 1.5;
  }

  &-xs {
    font-family: inherit;
    font-size: clamp(1.215rem, 2vw, 1.335rem);
    font-weight: 500;
    line-height: 1.5;
  }
}

.paragraph {
  font-family: inherit;
  font-size: clamp(1rem, 2vw, 1.125rem);
  text-wrap: balance;
  // color: var(--color-white-200);
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  text-transform: unset;
  transition: all 0.3s ease-in-out;

  &-inline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
  }

  &-darken {
    padding: 0.75rem 2rem;
    color: var(--color-white-100);
    background-color: var(--color-black-200);
    box-shadow: var(--shadow-medium);
  }

  &-neutral {
    padding: 0.75rem 2rem;
    color: var(--color-black-500);
    background-color: var(--color-white-100);
    box-shadow: var(--shadow-medium);
  }
}

// Header Section
.header {
  //   position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all 0.35s ease;

  &.on-scroll {
    background: var(--color-black-300);
    box-shadow: var(--shadow-medium);
  }
}

.navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.25rem;
  width: 100%;
  height: 4.25rem;
  margin: 0 auto;
}

.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
 color: blueviolet;
  text-transform: uppercase;
}

.menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 4rem 0 3rem;
  overflow: hidden;
  background-color: var(--color-black-300);
  box-shadow: var(--shadow-medium);
  transition: all 0.4s ease-in-out;

  &.is-active {
    top: 0;
    width: 100%;
    height: auto;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.25rem;
  }

  &-link {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    // color: var(--color-white-100);
    text-transform: uppercase;
    transition: all 0.3s ease;
  }

  &-block {
    display: inline-block;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    user-select: none;
    white-space: nowrap;
    text-align: center;
    margin-left: auto;
    padding: 0.65rem 1.5rem;
    border-radius: 3rem;
    text-transform: capitalize;
    color: var(--color-white);
    background-color: var(--color-blue-600);
    box-shadow: var(--shadow-medium);
    transition: all 0.3s ease-in-out;
  }

  // Media Query Breakpoint
  @media only screen and (min-width: 48rem) {
    position: relative;
    top: 0;
    width: auto;
    height: auto;
    padding: 0rem;

    margin-left: auto;
    background: none;
    box-shadow: none;

    &-inner {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      margin: 0 auto;
    }

    &-link {
      text-transform: capitalize;
    }

    &-block {
      margin-left: 2rem;
    }
  }
}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  order: -1;
  z-index: 10;
  width: 1.6rem;
  height: 1.15rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;

  // Media Query Breakpoint
  @media only screen and (min-width: 48rem) {
    display: none !important;
    visibility: hidden !important;

    
  }

  &-line {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 1rem;
    transform: rotate(0deg);
    background-color: blueviolet;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 0.5rem;
      width: 70%;
    }
    &:nth-child(3) {
      top: 1rem;
    }
  }

  &.is-active .burger-line {
    &:nth-child(1) {
      top: 0.5rem;
      transform: rotate(135deg);
    }
    &:nth-child(2) {
      opacity: 0;
      visibility: hidden;
    }
    &:nth-child(3) {
      top: 0.5rem;
      transform: rotate(-135deg);
    }
  }
}

// Footer css start

.Footer {
  width: 100%;
  padding: 30px;
  background-color: rgb(230, 225, 218);
}

.Footer > div {
  display: flex;
  justify-content: space-between;
}

.Footer > div > div {
  display: flex;
  gap: 4px;
}

.Footer p {
  cursor: pointer;
}

.Footer p:hover {
  color: rgb(169, 105, 229);
}

// Search Bar css

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 2px solid rgb(85, 26, 139, 1);
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: rgb(85, 26, 139, 1);
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid rgb(85, 26, 139, 1);
  background: rgb(85, 26, 139, 1);
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 40%;
  margin: auto;
  height: 36px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.wrap input {
  height: 36px;
}



hr{
  color: black;
  border: 0.1px black;
  background-color: black;
}



/* The sidepanel menu */
.sidepanel {
  height: 250px; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 8; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}


/* When you mouse over the navigation links, change their color */
.sidepanel a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidepanel .closebtn {
  position: absolute;
  
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style the button that is used to open the sidepanel */
.openbtn {
  font-size: 14px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}


@media screen and (min-width: 100px) and (max-width: 480px) {
  .Footer {
    padding: 15px 20px;
  }
  .Footer > div {
    display: flex;

    flex-direction: column;
    gap: 20px;
  }

  .wrap {
    width: 80%;
    margin: auto;
    height: 36px;
    padding: 10px 0;
    background-color: white;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }

  .sidepanel a {
    font-size: 16px;
    color: #d8d2d2;
  }
}