/* Home Page Css start */

.Home {
  padding: 30px;

  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  margin-top: 15px;
  background-color: rgb(243, 255, 249);
}

.MovieCard {
  width: 200px;
  background-color: white;
  padding-bottom: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.MovieCard p {
  padding: 10px;
  font-size: 14px;
  height:90px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.MovieCard p:hover {
  color: rgb(78, 47, 108);
}



/* Home Page Css End */


/* Section 3 pagination css start */

.Pagination {
  width: 100%;
  margin: auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.Pagination hr {
  width: 140px;
}
.PackageCard_Container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Pagination > div {
  display: flex;
  gap: 10px;
}
.Page {
  padding: 8px 12px;
  border: 0.1px solid rgb(188, 188, 188);
  border-radius: 4px;
  cursor: pointer;
}
.active,
.Page:hover {
  padding: 8px 12px;
  border: 0.1px solid rgb(188, 188, 188);
  border-radius: 4px;
  cursor: pointer;
  background-color: #a319d1;
  color: white;
}

/* Section 3 Pagination css end */

.hoverBtn{
  background-color: #a319d1;
  color: white;
}
.hoverBtn:hover{
  background-color: #8f639e;
}

.hoverBtn1{
  background-color: #19a6d1;
  color: white;
}
.hoverBtn1:hover{
  background-color: #63a6bb;
}

.hoverBtn2{
  background-color: rgb(8, 8, 134);
  color: white;
}
.hoverBtn2:hover{
  background-color: rgb(57, 57, 173);
}

.hoverBtn3{
  background-color: #f22234;
  color: white;
}
.hoverBtn3:hover{
  background-color: #cd7f85;
}

@media screen and (min-width: 100px) and (max-width: 480px) {
  .MovieCard {
    width: 160px !important;
    max-width: 190px !important;
    /* height: 210px; */
  }

  .Home {
    padding: 20px 10px;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center
  }
}